import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import NewsList from '../components/NewsList';

export default class NewsListPage extends React.Component {
  render() {
    const { data } = this.props;
    const { html, frontmatter } = data.codaPage;
    const { edges: posts } = data.allNewsItem;

    return (
      <Layout>
        <section className="section">
          {frontmatter.title && false ? (
            <h2 className="title">{frontmatter.title}</h2>
          ) : null}
          {html && false ? (
            <HTMLContent className="content" content={html} />
          ) : null}
          {html && false ? <hr /> : null}
          <NewsList news={posts} className="" />
        </section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query NewsListQuery($id: String!) {
    codaPage(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
    allNewsItem(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt: excerptLong
          id
          frontmatter {
            rowId
            title
            date(formatString: "l", locale: "fi")
          }
        }
      }
    }
  }
`;
